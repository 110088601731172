// FIXME: 시로사토 하드코딩 제거

import { Injectable } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { AuthService } from 'src/lib/auth/auth.service';
import { BookingApi } from 'src/lib/repository/booking/booking.api';
import { IPackagegoods } from 'src/lib/repository/packagegoods/packagegoods.model';
import { BrandService } from 'src/lib/services/brand.service';
import { environment } from '../../environments/environment';
import { DialogService } from './dialog.service';

export interface ShirosatoSchedule {
  startDt: string;
  returnDt: string;
  startPoint: string;
}

export const SHIROSATO_BRAND_ID = 2;
export const SHIROSATO_PACKAGEGOODS_ID = 95;
export const SHIROSATO_FIRST_START_DATE = '2024-09-01';
export const SHIROSATO_LAST_START_DATE = '2025-03-02';
export const SHIROSATO_MAX_BOOKING_CNT = 1;

/**
 * 메리트투어로부터 전달받은 2024 이바라키 시로사토 일정
 *
 * @see https://docs.google.com/spreadsheets/d/1WRN09bbcXFZ76mSgMsUHkhkBPezfIzYZ/edit?gid=276810554#gid=276810554
 */
export const SHIROSATO_SCHEDULE_LIST: ShirosatoSchedule[] = [
  {
    startDt: '2024-09-01',
    returnDt: '2024-09-07',
    startPoint: 'ICN',
  },
  {
    startDt: '2024-09-08',
    returnDt: '2024-09-14',
    startPoint: 'PUS',
  },
  {
    startDt: '2024-09-16',
    returnDt: '2024-09-21',
    startPoint: 'ICN',
  },
  {
    startDt: '2024-09-23',
    returnDt: '2024-09-28',
    startPoint: 'ICN',
  },
  {
    startDt: '2024-10-14',
    returnDt: '2024-10-19',
    startPoint: 'ICN',
  },
  {
    startDt: '2024-10-20',
    returnDt: '2024-10-26',
    startPoint: 'PUS',
  },
  {
    startDt: '2024-10-27',
    returnDt: '2024-11-02',
    startPoint: 'ICN',
  },
  {
    startDt: '2024-11-04',
    returnDt: '2024-11-09',
    startPoint: 'ICN',
  },
  {
    startDt: '2024-11-10',
    returnDt: '2024-11-16',
    startPoint: 'ICN',
  },
  {
    startDt: '2024-11-17',
    returnDt: '2024-11-23',
    startPoint: 'ICN',
  },
  {
    startDt: '2024-11-24',
    returnDt: '2024-11-30',
    startPoint: 'PUS',
  },
  {
    startDt: '2024-12-01',
    returnDt: '2024-12-07',
    startPoint: 'ICN',
  },
  {
    startDt: '2024-12-08',
    returnDt: '2024-12-14',
    startPoint: 'ICN',
  },
  {
    startDt: '2024-12-15',
    returnDt: '2024-12-21',
    startPoint: 'PUS',
  },
  {
    startDt: '2024-12-22',
    returnDt: '2024-12-28',
    startPoint: 'ICN',
  },
  {
    startDt: '2025-01-05',
    returnDt: '2025-01-11',
    startPoint: 'ICN',
  },
  {
    startDt: '2025-01-13',
    returnDt: '2025-01-18',
    startPoint: 'ICN',
  },
  {
    startDt: '2025-01-19',
    returnDt: '2025-01-25',
    startPoint: 'PUS',
  },
  {
    startDt: '2025-01-26',
    returnDt: '2025-02-01',
    startPoint: 'ICN',
  },
  {
    startDt: '2025-02-02',
    returnDt: '2025-02-08',
    startPoint: 'ICN',
  },
  {
    startDt: '2025-02-09',
    returnDt: '2025-02-15',
    startPoint: 'ICN',
  },
  {
    startDt: '2025-02-16',
    returnDt: '2025-02-22',
    startPoint: 'PUS',
  },
  {
    startDt: '2025-02-23',
    returnDt: '2025-03-01',
    startPoint: 'ICN',
  },
  {
    startDt: '2025-03-02',
    returnDt: '2025-03-08',
    startPoint: 'ICN',
  },
];

/**
 * 2024-06-11 메리트투어 남태희 상무님 요청으로 이바라키 시로사토 패키지 하드코딩
 * FIXME
 * @deprecated
 */
@Injectable({
  providedIn: 'root',
})
export class ShirosatoService {
  constructor(
    private authService: AuthService,
    private brandService: BrandService,
    private dialogService: DialogService,
    private bookingApiService: BookingApi,
  ) {}

  /**
   * 특별 예약 기간동안 이미
   */
  canBooking(): Observable<boolean> {
    const { id } = this.authService.account || {};

    if (!id) {
      this.dialogService
        .alert('EWRCⅡ 회원님만 이용하실수 있는 상품입니다.')
        .subscribe();

      return of(false);
    }

    const params = {
      representativeId: id,
      packagegoodsId: SHIROSATO_PACKAGEGOODS_ID,
      fromStartDate: SHIROSATO_FIRST_START_DATE,
      toStartDate: SHIROSATO_LAST_START_DATE,
      bookingStateCodeIn: ['RECEIVE', 'ESTIMATE', 'PREPARE', 'COMPLETE'],
    };

    return this.bookingApiService.findPage(params).pipe(
      map(({ content }) => {
        const canBooking =
          !content || content.length < SHIROSATO_MAX_BOOKING_CNT;

        if (!canBooking) {
          // 예약 횟수 초과시 안내
          this.dialogService
            .alert(
              `${SHIROSATO_FIRST_START_DATE} ~ ${SHIROSATO_LAST_START_DATE} 기간중 ${SHIROSATO_MAX_BOOKING_CNT}회만 예약하실수 있습니다.`,
            )
            .subscribe();
        }

        return canBooking;
      }),
    );
  }

  /**
   * EWRC 이바라키 시로사토 상품 여부 조회
   */
  isShirosato(packagegoods?: IPackagegoods): boolean {
    if (!packagegoods) {
      return false;
    }

    const isEwrc = this.brandService.brand.id === SHIROSATO_BRAND_ID;
    const isShirosato = packagegoods.id === SHIROSATO_PACKAGEGOODS_ID;
    const isShirosatoICN =
      packagegoods.id === environment.packagegoodsId.ibarakiSirosatoFromIncheon;

    return isEwrc && (isShirosato || isShirosatoICN);
  }

  isTempAvailableStart(startDt: string): boolean {
    if (startDt === '2025-01-12' || startDt === '2025-01-13') {
      return true;
    }

    return false;
  }

  isTempAvailableReturn(startDt: string, returnDt: string): boolean {
    if (startDt === '2025-01-13' && returnDt === '2025-01-18') {
      return true;
    }

    return false;
  }

  /**
   * 가능 일정 조회
   */
  getAvailSchedule(
    startPoint: string,
    startDt: string,
  ): ShirosatoSchedule | undefined {
    const availSchedule = SHIROSATO_SCHEDULE_LIST.find((schedule) => {
      const isStartPointSame = schedule.startPoint === startPoint;
      const isStartDtSame = schedule.startDt === startDt;

      return isStartPointSame && isStartDtSame;
    });

    return availSchedule;
  }

  /**
   * 출발 가능일 여부 조회
   */
  isStartDtAvail(startPoint: string, startDt: string): boolean {
    // return !!this.getAvailSchedule(startPoint, startDt);
    if (startDt === '2025-01-12') {
      return false;
    }

    return true;
  }

  /**
   * 복귀 가능일 여부 조회
   */
  isReturnDtAvail(
    startPoint: string,
    startDt: string,
    returnDt: string,
  ): boolean {
    // const shirosatoSchedule = this.getAvailSchedule(startPoint, startDt);

    // return shirosatoSchedule?.returnDt === returnDt;
    return true;
  }
}
